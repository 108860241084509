<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <molecule-list-table></molecule-list-table>
    </v-col>
  </v-row>
</template>

<script>

// import moleculeListTable from '@/components/tables/pharmacyMoleculeListTable.vue'

export default {
    name: 'PharmacyMoleculeList',
    components: {
        moleculeListTable: () => import('@/components/tables/pharmacyMoleculeListTable.vue'),
    },
    setup() {
        return {}
    },
}
</script>
